import {extendObservable} from 'mobx';
import OrderAPI from '../services/OrderAPI';
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import {ORDER_STATUS} from '~/models/OrderStatusModel';
import EuroOrderItemModel from '~/models/euro/EuroOrderItemModel';
import SportbayOrderModel from '~/models/sportbay/SportbayOrderModel';
import EuroRecomendedItemModel from '~/models/euro/EuroRecomendedItemModel';
import EuroShippingDetails from '~/models/euro/EuroShippingDetails';

class OrderStore {
  totalPages = 0;
  page = 0;
  size = 20;
  filterStatus = undefined;
  filterStaff = undefined;
  userUuid = undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      loading: false,
      loadingReturned:false,
      order: undefined,
      orders: [],
    });
  }

  get merchant() {
    return this.rootStore.usersStore.userMerchant;
  }

  /**Limpa dados de pedidos */
  reset() {
    this.order = undefined;
    this.orders = [];
    this.totalPages = 0;
    this.page = 0;
    this.userUuid = undefined;
  }

  /**Retorna instancia da notification store */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna pedido do usuario */
  async get(orderUuid, userUuid = this.userUuid) {
    this.loading = true;
    this.userUuid = userUuid;
    // const response = await OrderAPI.getOrderUser(orderUuid, this.userUuid);
    const response = await OrderAPI.getOrderUserMKT(orderUuid, this.userUuid);
    if (!response.error) {
      this.order = new SportbayOrderModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async updateObservation(orderUuid, observation) {
    const response = await OrderAPI.updateObservation(orderUuid, observation);

    if (response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Ocorreu um erro ao tentar salvar a observação'
      );
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Observação salva com sucesso!'
      );
    }
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, statusValue, sort) {
    this.page = numPage;
    this.size = size;

    sort
      ? await this.getList(sort, size, statusValue)
      : await this.getList(this.sort, size, statusValue);

    return true;
  }

  // createSearch({
  //   status = undefined,
  //   orderNumber = undefined,
  //   erpNumber = undefined,
  //   startDate = undefined,
  //   endDate = undefined,
  //   merchant = undefined
  // }) {

  // }

  async getOrderByStaff(uuidStaff) {
    this.filterStaff = uuidStaff;

    await this.getList(this.filterStatus);
    this.loading = true;
    if (uuidStaff !== 'ALL')
      this.preOrders = this.preOrders.filter(
        (pO) =>
          pO.customer.administrator &&
          pO.customer.administrator.uuid === uuidStaff
      );
    this.loading = false;
  }

  async getListByMerchant(merchantName, sort) {
    const filter = (merchantName) ? 'merchant_uuid:' + merchantName : undefined;

    sort
      ? await this.getList(sort, this.size, filter)
      : await this.getList('created,desc', this.size, filter);
  }

  async getListByStatus(statusName, sort) {
    this.filterStatus = statusName;

    sort
      ? await this.getList(sort, this.size, statusName)
      : await this.getList('created,desc', this.size, statusName);
  }

  async getListByAdmin(
    uuidAdmin = '',
    statusValue,
    sort = 'created,desc',
    size = this.size,
    page,
    filterType = '',
    filter = '',
    tab,
    fromDate = null,
    toDate = null,
    merchant = null,
    valueRange = {},
    paymentType = null,
    customer = {},
    shippingAddress = {}
  ) {
    this.loading = true;
    let params;
    let searchParams;
    let dateRange;

    // Verifica se existem uma pesquisa por data e formata os parametros da requisição de acordo com o range
    if (fromDate) {
      toDate
        ? (dateRange = `created>${fromDate},created<${toDate},`)
        : (dateRange = `created>${fromDate},`);
      sort = 'created,asc';
    } else if (toDate) {
      dateRange = `created<${toDate},`;
    }

    // Verifique se existe um filtro diferente do status_name e formata os parametros a requisição
    filterType
      ? statusValue
        ? filter
          ? (searchParams = `${filterType}:${filter},status_statusName:${statusValue}`)
          : (searchParams = `status_statusName:${statusValue}`)
        : (searchParams = `${filterType}:${filter}`)
      : (searchParams = `status_statusName:${statusValue}`);


    if (merchant) {
      searchParams = `merchant_uuid:${merchant},` + searchParams;
    }

    if (valueRange.from) {
      let valueSearch = '';
      if (valueRange.to)
        valueSearch = `total>${valueRange.from},total<${valueRange.to}`;
      else
        valueSearch = `total>${valueRange.from}`;

      searchParams = `${valueSearch},${searchParams}`;

    } else if (valueRange.to) {
      let valueSearch = '';
      if (valueRange.from)
        valueSearch = `total>${valueRange.from},total<${valueRange.to}`;
      else
        valueSearch = `total<${valueRange.to}`;

      searchParams = `${valueSearch},${searchParams}`;
    }

    if (customer) {
      if (customer.name) {
        searchParams = `customer_firstName;${customer.name},${searchParams}`;
      }
      if (customer.document) {
        searchParams = `customer_document;${customer.document},${searchParams}`;
      }

      if (customer.email) {
        searchParams = `customer_user_email;${customer.email},${searchParams}`;
      }
      if (customer.mobile) {
        searchParams = `customer_mobile;${customer.mobile},${searchParams}`;
      }
      if (customer.phone) {
        searchParams = `customer_phone;${customer.phone},${searchParams}`;
      }
    }

    if (shippingAddress) {
      if (shippingAddress.addresses_city) {
        searchParams = `shippingAddress_city;*${shippingAddress.addresses_city}*,${searchParams}`;
      }
      if (shippingAddress.addresses_block) {
        searchParams = `shippingAddress_block;*${shippingAddress.addresses_block}*,${searchParams}`;
      }
      if (shippingAddress.addresses_zipCode) {
        searchParams = `shippingAddress_zipCode:${shippingAddress.addresses_zipCode.match(/\d+/g).join('')},${searchParams}`;
      }
    }

    if (paymentType) {
      //paymentDetails
      searchParams = `order_paymentDetails_paymentType:${paymentType},${searchParams}`;
    }
    // Verifica se existe se existe data para formatar o valor *search* dos parametros
    if (dateRange) {
      params = {
        search: dateRange + searchParams,
        page: page ? page : tab ? 0 : this.page,
        size,
        sort,
      };
    } else {
      params = {
        search: searchParams,
        page: page ? page : tab ? 0 : this.page,
        size,
        sort,
      };
    }

    this.searchParams = params;

    // const response = await OrderAPI.list(params);
    const response = await OrderAPI.listMKT(params);
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;

      // .filter((order) => order.getAdmin.uuid !== uuidAdmin);
      this.orders = response?.content?.map((order) => new SportbayOrderModel(order));
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;

    return response;
  }

  /**Busca lista de pedidos em analise. */
  async listAnalise(sort = 'created', size = 20) {
    return await this.getList(sort, size, ORDER_STATUS.ANALYSIS);
  }

  /**troca texto observação orderITem*/
  async changeObservationItem(orderUuid, itemUuid, textObservation) {
    this.loading = true;
    const response = await OrderAPI.changeObservationItem(
      orderUuid,
      itemUuid,
      textObservation
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**troca texto observação orderITem*/
  async changeMainObservation(orderUuid, preOrderUuid, textObservation) {
    this.loading = true;
    if (!orderUuid) {
      await this.createOrder(preOrderUuid, undefined, textObservation);
      return true;
    } else {
      const response = await OrderAPI.changeObservation(
        orderUuid,
        textObservation
      );
      if (response.error)
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return false;
    }
  }

  async setShippingOptions(orderUuid, customerUiid, shippingOptionList) {
    let shippingDetails = new EuroShippingDetails(shippingOptionList);
    this.loading = true;
    const response = await OrderAPI.setShippingOptions(
      orderUuid,
      shippingDetails
    );

    if (!response.error) {
      await this.changeStatusOrder('CHECKOUT', orderUuid, customerUiid);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza quantidade do item */
  updateAmountItem(product, preOrderItem, amount) {
    const item = this.order.orderItems.find(
      (item) =>
        item.product.uuid === product && item.preOrderItem === preOrderItem
    );
    if (!item) return;
    if (amount === 0) {
      this.order = this.order.orderItems.filter(
        (oItem) => !JSON.stringify(item) === JSON.stringify(oItem)
      );
    }
    item.amount = amount;
  }

  /**Cria novo pedido */
  async addNewOrderItem(
    preOrderUuid,
    product,
    preOrderItem,
    orderUuid,
    customerUuid
  ) {
    this.loading = true;
    const orderItem = new EuroOrderItemModel({
      product,
      amount: 1,
      preOrderItem,
    });
    if (!orderUuid) return await this.createOrder(preOrderUuid, orderItem);
    else return await this.addProduct(orderItem, customerUuid, orderUuid);
  }

  /**Adiciona produto ao carrinho... */
  async addProduct(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.addItems(customerUuid, orderUuid, [item]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria pedido à partir de um preOrder */
  async createOrder(preOrderUuid, orderItem, observation) {
    this.loading = true;
    const euroOrder = orderItem
      ? new SportbayOrderModel({orderItems: [orderItem], merchant: this.merchant})
      : new SportbayOrderModel({
        orderItems: [],
        merchant: this.merchant,
        observation,
      });

    const response = await OrderAPI.create(preOrderUuid, euroOrder);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria novo item recomendado */
  async addProductRecomended(orderUuid, product) {
    this.loading = true;
    const recomendedItem = new EuroRecomendedItemModel({
      product: {uuid: product.uuid},
      order: {uuid: orderUuid},
    });
    const response = await OrderAPI.addProductRecomended(orderUuid, [
      recomendedItem,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria novo item recomendado */
  async removeProductRecomended(orderUuid, remendedItem) {
    this.loading = true;
    const response = await OrderAPI.removeRecomendedItems(orderUuid, [
      remendedItem,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeObservationRecomended(
    orderUuid,
    recomendedUuid,
    textObservation
  ) {
    this.loading = true;
    const response = await OrderAPI.changeObservationRecomended(
      orderUuid,
      recomendedUuid,
      textObservation
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeImporter(orderUuid, importer) {
    this.loading = true;
    const response = await OrderAPI.changeImporter(orderUuid, importer);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeAmountRecomended(orderUuid, recomendedUuid, amount) {
    this.loading = true;
    const response = await OrderAPI.changeAmountRecomended(
      orderUuid,
      recomendedUuid,
      amount
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Busca lista de pedidos */Emailget

  async getList(
    sort = 'created,desc',
    size = this.size,
    typeView = this.filterStatus
  ) {
    this.loading = true;
    // let params = { page: this.page, size, sort, status_name: typeView ? typeView : null, filterType:'', filter: '' }
    let params;
    let test;
    let filterPropName = (typeView && typeView.indexOf(':')) ? '' : 'status_statusName:';

    typeView ? (test = `${filterPropName}${typeView}`) : (test = "'':''");

    params = {
      search: this.searchParams?.search || test,
      page: this.page,
      size,
      sort: sort || this.searchParams?.sort || 'created,desc',
    };

    const {administrator} = await this.rootStore.administratorStore;
    const isSuperAdmin = administrator && (await administrator.isSuperAdmin);
    // const response = await OrderAPI.list(params);
    const response = await OrderAPI.listMKT(params);

    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      let listOrder = response.content
        .map((order) => new SportbayOrderModel(order));

      this.orders = listOrder;

    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loading = false;
    return response;
  }

  /**Altera status do pedido(carrinho) atual */
  async changeStatusOrder(newStatus, orderUuid, customerUuid) {
    this.loading = true;
    const response = await OrderAPI.changeOrderStatus(customerUuid, orderUuid, {
      statusName: newStatus,
    });
    if (!response.error) {
      this.order = new SportbayOrderModel(response);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Resposta enviada ao cliente'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Remove produto do carrinho...*/
  async updateOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.addItems(customerUuid, orderUuid, [item]);
    this.loading = false;
    return response;
  }

  /**Remove produto do carrinho...*/
  async removeOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.removeItems(customerUuid, orderUuid, [
      item,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Faz a contagem de quantos items existem no pedido */
  countItems() {
    if (!this.order) return 0;
    // this.loading = true;
    let totalItems = 0;
    this.order.orderItems.map((product) => {
      totalItems += product.amount;
    });


    (this.order.orderKits || []).map((product) => {
      totalItems += product.amount;
    })

    // this.loading = false;
    return totalItems;
  }

  /**Consulta o relatórios de vendas*/
  async getSales(from, to) {
    this.loading = true;
    const params = {
      paymentsTypes: 'CREDIT_CARD;INVOICE',
      status: 'CART;NEW;ANALYSIS;CANCELED',
      from,
      to,
    };
    const response = await OrderAPI.getSales(params);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.sales = response;
      if (this.sales.percentageOrdersConverted === 'NaN')
        this.sales.percentageOrdersConverted = 0;
      this.getSalesDetails(from, to);
    }
    this.loading = false;
  }

  /**Consulta os detalhes do relatórios de vendas*/
  async getSalesDetails(from, to, page) {
    this.loading = true;
    const params = {
      paymentsTypes: 'CREDIT_CARD;INVOICE',
      status: 'CART;NEW;ANALYSIS;CANCELED',
      from,
      to,
      page,
      size: 10,
    };
    const response = await OrderAPI.getSalesDetails(params);
    this.salesDetails = [];

    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      response.content
        .sort((a, b) =>
          a.productName.toUpperCase() > b.productName.toUpperCase() ? 1 : -1
        )
        .map((i) => this.salesDetails.push(i));
    }
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  /**cancela pedido filho*/
  async cancelOrderMerchant(orderUuid) {
    this.loading = true;

    const response = await OrderAPI.cancelOrder(orderUuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Pedido cancelado com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
  }

  /**cancela pedido filho*/
  async chargebackOrderMerchant(orderUuid) {
    this.loading = true;

    const response = await OrderAPI.chargebackOrder(orderUuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Pedido cancelado com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
  }

  /**cancela pedido filho*/
  async returnedOrderMerchant(orderUuid) {
    this.loadingReturned = true;

    const response = await OrderAPI.returnedOrder(orderUuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Pedido cancelado com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loadingReturned = false;
  }

  async orderLoss(orderUuid) {
    this.loadingReturned = true;

    const response = await OrderAPI.lossOrder(orderUuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Status alterado com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loadingReturned = false;
  }

  async paymentConfirm(orderUuid) {

    const response = await OrderAPI.confirmPayment(orderUuid);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Status alterado com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

  }

  /**adiciona observação ao pedido*/
  async observationOrderMerchant(orderUuid, data) {
    this.loading = true;

    const response = await OrderAPI.observationOrder(orderUuid, data);

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Observação adicionada com sucesso.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
  }

  /** envia e-mail para carrinho abandonado com cupom */
  async sendEmailAbandonedCart(cart_id, cupom, orderUuid, userUuid) {
    this.loading = true;

    const response = await OrderAPI.sendEmailAbandonedCart(cart_id, cupom)

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "E-mail enviado com sucesso.");
      await this.get(orderUuid, userUuid)
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
  }

  /** envia whatsapp para carrinho abandonado com cupom */
  async sendWhatsappAbandonedCart(cart_id, cupom, orderUuid, userUuid) {
    this.loading = true;

    const response = await OrderAPI.sendWhatsappAbandonedCart(cart_id, cupom)

    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "E-mail enviado com sucesso.");
      await this.get(orderUuid, userUuid)
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
  }

  /**
   * Busca Logs por idOrder
   */

  async getLogByOrderUuid(idOrder, page) {

    const response = await OrderAPI.getLogByOrderUuid(idOrder, page)

    return response
  }
}

export default OrderStore;
