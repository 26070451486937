import {action, decorate, extendObservable} from 'mobx';
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import {currencyToNumber, isArrayEmpty, onlyUnique, onlyUniqueByProp, validateImgLink} from '~/helpers/utils/Functions';
import SportbayCategoryModel from '~/models/sportbay/SportbayCategoryModel';
import SportbayFileModel from '~/models/sportbay/SportbayFileModel';
import SportbayProductComparativeModel from '~/models/sportbay/SportbayProductComparativeModel';
import {ProductTypeEnum} from '~/helpers/utils/Enums';
import EuroApplicationModel from '~/models/euro/EuroApplicationModel';
import SportbayProductModel from '~/models/sportbay/SportbayProductModel';
import SupplierModel from '~/models/SupplierModel';
import CategoryAPI from '../services/CategoryAPI';
import ProductAPI from '../services/ProductAPI';
import UploadAPI from '../services/UploadAPI';
import {v4 as uuidv4} from 'uuid';

/**Valores inicias de variaveis observadas */
const initValues = {
  loadingApprove: false,
  loading: false,
  product: undefined,
  loadingProductsCrossSell:false,
  loadingAssociatedProduct: false,
  products: [],
  properties: [],
  classes: [],
  pricings: [],
  applications: [],
  suppliers: undefined,
  tabType: undefined,
  loadingSearchCategoryFeature: false,
  initialFeatureValue: true,
  categoryFeatureInitial: [],
  inactiveProductsLoading: false,
  activeProductsLoading: false,
  AssociateProducts: [],
};

class ProductStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'name';
  filter = '';
  filter_merchant_uuid = '';
  filter_brand_uuid = '';
  filter_active = undefined;
  filter_approved = undefined;
  filter_sku = '';
  categoriesRemove = [];
  categoriesAdd = [];
  collectionsRemove = [];
  collectionsAdd = [];
  variations = [];
  princingsRemove = [];
  categoryFeatureValues = [];
  featureInProduct = false;
  isInitialComparative = false;
  inactiveProductsLoading = false;
  activeProductsLoading = false;
  AssociateProducts= []

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {...initValues});
  }

  reset() {
    this.product = null;
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 10;
    this.sort = 'name';
    this.filter = '';
    this.filter_merchant_uuid = '';
    this.filter_brand_uuid = '';
    this.filter_active = undefined;
    this.filter_approved = undefined;
    this.filter_sku = '';
    this.categoriesRemove = [];
    this.categoriesAdd = [];
    this.variations = [];
    this.princingsRemove = [];
    this.applications = [];
    this.propertiesStore.reset();
    this.categoryFeatureValues = [];
    this.featureInProduct = false;
    this.isInitialComparative = false;
    this.categoryFeatureInitial = ''
  }

  clearFilters = () => {
    this.filter = '';
    this.filter_merchant_uuid = '';
    this.filter_brand_uuid = '';
    this.filter_active = undefined;
    this.filter_sku = '';
    this.filter_approved = undefined;
  }

  setFilter(filter) {
    this.filter = filter;
  }

  setFilter_merchant_uuid(filter_merchant_uuid) {
    this.filter_merchant_uuid = filter_merchant_uuid;
  }

  setFilter_brand_uuid(filter_brand_uuid) {
    this.filter_brand_uuid = filter_brand_uuid;
  }

  setFilter_active(filter_active) {
    this.filter_active = filter_active;
  }

  setFilter_filter_approved(filter_approved) {
    this.filter_approved = filter_approved;
  }

  setFilter_sku(filter_sku) {
    this.filter_sku = filter_sku;
  }

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna instancia da propertiesStore */
  get propertiesStore() {
    return this.rootStore.propertiesStore;
  }

  /**Retorna o merchant do usuário atual */
  get merchant() {
    return this.rootStore.usersStore.user.merchant;
  }

  getCustomerType(customerType) {
    return this.rootStore.customerTypeStore.getCustomerType(customerType);
  }

  async associatedProducts(product){
    this.loadingAssociatedProduct = true
    if(this.product.productAssociated.find(p => p === product.uuid)){
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Produto já está na lista');
      return;
    }else if(this.product.productAssociated.length >= 2){
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Limite de 2 produtos atingidos');
      return;
    }

    let listAssociated = [];
    listAssociated.push(product.uuid);

    const response = await ProductAPI.associateProduct(this.product.uuid, listAssociated);
    if (!response.error) {
    this.product.productAssociated.push(product.uuid);
    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Produto adiconado com sucesso');
    await this.getAssociateProducts()
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return;
    }
    this.loadingAssociatedProduct = false;
  }
 async disassociateProduct(productId){
    this.loadingAssociatedProduct = true

    // Chame a API para comunicar a desassociação.
    const response = await ProductAPI.disassociateProduct(this.product.uuid, [productId]);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "nao foi possivel desassociar produto");
      return;
    }
    const productIndex = this.product.productAssociated.findIndex(p => p === productId);
   if(productIndex !== -1){
      this.product.productAssociated.splice(productIndex, 1);
      await this.getAssociateProducts()
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Produto removido");
    } else {
    }

   this.loadingAssociatedProduct = false;

 }
 async getAssociateProducts(Uuid){
     const response = await ProductAPI.getAssociated(this.product.uuid || Uuid)
     if(!response.error){
       if (this.product) {
       this.product.productAssociated = response.map((a) => a.uuid)
       }
       else {
       }
     this.AssociateProducts = response
     }else{
       if(this.products.productType === ProductTypeEnum.MODEL){
       this.toastHelper.notify(STATUS_HELPER.ERROR, "Erro ao buscar produtos associados");
       }
     }
  }

  createNewPrices() {
    var customerType = this.getCustomerType('A');
    let price = {price: 0, customerType: customerType};

    return [price];
  }

  /**Cria novo produto */
  createEmptyProduct() {
    this.product = undefined;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.product = new SportbayProductModel({prices: this.createNewPrices(), merchant: merchant});
    this.product.active = false;
    this.product.parent = true;
  }

  /**Atualiza propriedades do produto. */
  async updateProp(prop, value) {
    const product = this.product;
    switch (prop) {
      case 'name':
        product.name = value;
        if (!product.shortName) product.shortName = value;
        break;
      case 'description':
        product.description = value;
        if (!product.shortDescription || product.shortDescription.length < 20)
          product.shortDescription = value;
        break;
      case 'observation':
        product.observation = value;
        if (!product.observation || product.observation.length < 500)
          product.observation = value;
        break;
      case 'fakePrice':
        product.fakePrice = currencyToNumber(value);
        if (!product.price) product.price = currencyToNumber(value);
        break;
      case 'supplier':
        product.setSupplier({uuid: value.value});
        break;
      /*  case 'productProperties':
                 const variations = [new ProductPropertyModel({ uuid: value.value, propertyValue: value.label })];
                 product.productProperties = variations.length > 0 ? variations : undefined;
                 break; */
      case 'price':
        if (product.prices.length > 0) {
          product.prices[0].price = currencyToNumber(value);
        } else if (product.prices.length === 0) {
          product.prices.push({
            price: currencyToNumber(value),
            customerType: this.rootStore.customerTypeStore.getDefaultCustomerType(),
          });
        }
        break;
      case 'numberPrice':
      case 'namePrice':
      case 'absoluteDiscount':
      case 'percentualDiscount':
      case 'weight':
      case 'height':
      case 'width':
      case 'length':
      case 'shippingWeight':
      case 'shippingHeight':
      case 'shippingWidth':
      case 'shippingLength':
      case 'stock':
      case 'committedStock':
      case 'securityStock':
      case 'deliveryTime':
        const number = currencyToNumber(value);
        number > 0 ? (product[prop] = number) : (product[prop] = 0);
        break;
      case 'ordering':
        product.ordering = value;
        break;
      default:
        product[prop] = value;
    }
    this.product = new SportbayProductModel(product);
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.product.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.product.categories.some((mCat) => mCat.uuid === cat.uuid)
    );

    return [...this.product.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Manipula lista de coleções selecionadas ou descelecionadas */
  setCollections(selecteds) {
    this.collectionsRemove = this.product.collections.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.collectionsAdd = selecteds.filter(
      (cat) => !this.product.collections.some((mCat) => mCat.uuid === cat.uuid)
    );

    return [...this.product.collections, ...this.collectionsAdd]
      .filter((f) => this.collectionsRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  removeItensDuplicados(lista) {
    const itensUnicos = {};
    const listaSemDuplicatas = [];
    for (const item of lista) {
      if (!itensUnicos[item.uuid]) {
        itensUnicos[item.uuid] = true;
        listaSemDuplicatas.push(item);
      }
    }
    return listaSemDuplicatas;
  }

  async handleAddFeatureClickCategory(categoryUuid) {
    this.loading = true;
    const response = await CategoryAPI.getAllFeaturesCategory(categoryUuid);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /* Adição de aplicação */
  addAppl(data) {
    this.product.applications.push(
      new EuroApplicationModel({uuid: data.value, name: data.label})
    );
  }

  addSuppl(data) {
    this.product.supplier = new SupplierModel({uuid: data.value});
  }

  /**Adiciona imagem ao produto */
  handleProductImages(tag, file) {
    if (!this.product) this.createEmptyProduct();
    const product = this.product;

    const fileItem = product.files.find((file) => file.metaTags[0] === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new SportbayFileModel({file, ...file, metaTags: [tag]});
      product.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  async addPricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.addProcuctPricing(
      this.product.uuid,
      propertyGroup
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getParentProducts(name) {
    const response = await ProductAPI.getParentProducts(name);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Vincula princig no produto */
  async removePricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.removeProcuctPricing(
      this.product.uuid,
      propertyGroup.uuid
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, productType, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'created,desc';
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;

    switch (this.tabsPage) {
      case 'APPROVAL':
        await this.listProductsForApproval('approval', 'model');
        break;
      case 'APPROVAL-SUB':
        await this.listProductsForApproval('approval', 'variation');
        break;
      case 'REPPROVAL':
        await this.listProductsForApproval('denied', 'model');
        break;
      case 'REPPROVAL-SUB':
        await this.listProductsForApproval('denied', 'variation');
        break;
      default:
        await this.getListByType(productType);
    }
  }

  async searchByNameRelacionados(inputValue, size = 12 ,sort){
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter = inputValue;
    this.size = size;
    await this.getListByTypeCrossSell("MODEL");

  }
  async searchBySkuRelacionados(inputValue, size = 12, sort){
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_sku = inputValue;
    this.size = size;
    await this.getListByTypeCrossSell("MODEL");

  }
  async setPageCrossSell(numPage, size = 10, productType, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'created,desc';
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;
    await this.getListByTypeCrossSell(productType);
  }
  async searchByMerchantCrossSell( size = 10, productType, sort, merchant) {
    this.size = size;
    this.filter_merchant_uuid = merchant && merchant.value;
    this.sort = sort ? sort : 'created,desc';
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;
    await this.getListByTypeCrossSell(productType);
  }
  async searchByActiveStatus(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort : 'created,desc';
    this.filter_active = inputValue;
    this.size = size;

    return await this.getListByType(this.tabType);
  }

  async searchByActiveSituation(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_approved = inputValue;
    this.size = size;
    if (this.tabType == 'APPROVAL')
      await this.listProductsForApproval('approval', 'model');
    else if (this.tabType)
      await this.getListByType(this.tabType);

    return this.getListSelect();
  }

  async searchByBrand(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_brand_uuid = inputValue;
    this.size = size;
    if (this.tabType == 'APPROVAL')
      await this.listProductsForApproval('approval', 'model');
    else if (this.tabType)
      await this.getListByType(this.tabType);

    return this.getListSelect();
  }

  async searchBySku(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    // this.filter = inputValue;
    this.filter_sku = inputValue;
    this.size = size;
    if (this.tabType !== 'MODEL' && this.tabType !== 'VARIATION'){
      switch (this.tabType) {
        case 'APPROVAL':
          await this.listProductsForApproval('approval', 'model');
          break;
        case 'APPROVAL-SUB':
          await this.listProductsForApproval('approval', 'variation');
          break;
        case 'REPPROVAL':
          await this.listProductsForApproval('denied', 'model');
          break;
        case 'REPPROVAL-SUB':
          await this.listProductsForApproval('denied', 'variation');
          break;
        case 'LOWER-PRICE':
          await this.getProductLowerPrice();
          break;
        default:
          await this.getListByType(this.tabType);
      }
    }
    else
      await this.getListByType(this.tabType);
    return this.getListSelect();
  }

  async searchBySkuVariation(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_sku = inputValue;
    this.size = size;
    this.tabType = "VARIATION"

    await this.getListByType(this.tabType);
  }

  async searchByNameVariation(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter = inputValue;
    this.size = size;
    this.tabType = "VARIATION"

    await this.getListByType(this.tabType);

  }

  async searchByName(inputValue, sort, size = 12) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter = inputValue;
    this.size = size;
    if (this.tabType !== 'MODEL' && this.tabType !== 'VARIATION')
      switch (this.tabType) {
        case 'APPROVAL':
          await this.listProductsForApproval('approval', 'model');
          break;
        case 'APPROVAL-SUB':
          await this.listProductsForApproval('approval', 'variation');
          break;
        case 'REPPROVAL':
          await this.listProductsForApproval('denied', 'model');
          break;
        case 'REPPROVAL-SUB':
          await this.listProductsForApproval('denied', 'variation');
          break;
        default:
          await this.getListByType(this.tabType);
      }
    else
      await this.getListByType(this.tabType);
    return this.getListSelect();
  }

  async searchByMerchantFix(merchantUuid, sort) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_merchant_uuid = merchantUuid;
    if (this.tabType == 'VARIATION')
      await this.listProductsForApproval('approval', 'variation');
    else
      await this.getListByType(this.tabType);
    return this.getListSelect();
  }

  async searchByMerchant(merchantUuid, sort) {
    this.page = 0;
    this.sort = sort ? sort: 'created,desc';
    this.filter_merchant_uuid = merchantUuid;
    if (this.tabType !== 'MODEL' && this.tabType !== 'VARIATION')
      switch (this.tabType) {
        case 'APPROVAL':
          await this.listProductsForApproval('approval', 'model');
          break;
        case 'APPROVAL-SUB':
          await this.listProductsForApproval('approval', 'variation');
          break;
        case 'REPPROVAL':
          await this.listProductsForApproval('denied', 'model');
          break;
        case 'REPPROVAL-SUB':
          await this.listProductsForApproval('denied', 'variation');
          break;
        default:
          await this.getListByType(this.tabType);
      }
    else
      await this.getListByType(this.tabType);
    return this.getListSelect();
  }

  async getListByTypeCrossSell(productType = this.tabType) {
    this.loadingProductsCrossSell = true
    let search = '';
    this.sort = "created,desc";
    this.filter_active = true


    if (this.filter)
      search = `name;*${this.filter}*`;
    if (this.filter_merchant_uuid)
      search = `merchant_uuid:${this.filter_merchant_uuid},${search}`;
    if (this.filter_sku)
      search = `skuCode;*${this.filter_sku}*,${search}`;

     search = `active:${this.filter_active},${search}`;

    const params = {
      page: this.page,
      size: this.size,
      sort:  this.sort,
      search: search,
    };
    const response = await ProductAPI.listByType(params, productType);

    if (!response.error && response.content.length >= 1) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else if(response.content.length < 1){
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Produto não encontrado.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loadingProductsCrossSell = false
  }

  /**Salva um novo produto */
  async save() {
    this.loading = true;

    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    const merchant = (this.merchant && this.merchant.uuid) ? this.merchant : undefined;
    this.product.merchant = merchant;
    const data = JSON.stringify(this.product);
    let response = await ProductAPI.save(data);

    if (!response.error) {
      const product = new SportbayProductModel(response.data);
      await this.handleCategories(product.uuid);
      if (this.categoryFeatureValues.length > 0 && this.featureInProduct) {
        await this.saveProductFeatureValue(product.uuid);
      }
      ///await this.handlePricings(product.uuid);
      // this.sendNewFiles(files, product.uuid);
      this.createEmptyProduct();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto Cadastrado');
    } else {
      // this.product.categories = categories;
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        response.error
      );
    }
    this.loading = false;
    return response;
  }

  /**Envia propriedades */
  async handlePricings(productUuid) {
    const groups = [];
    this.propertiesStore.classesSelecteds.map((cls) =>
      groups.push(...cls.groups)
    );

    //Pega todos os princings ativos ( > 0)
    const arrayAdd = [];
    let arrayRemoveds = [];

    //Se grupos forem  > 0 verifica o que é novo e o que foi removido.
    if (groups.length > 0) {
      groups.map((pricing) => {
        if (pricing.priceIncrease > 0) arrayAdd.push(pricing);
        else if (pricing.uuid && pricing.priceIncrease <= 0)
          arrayRemoveds.push(pricing);
        return pricing;
      });
      //Verifica variações que foram removidas.
      arrayRemoveds = [
        ...arrayRemoveds,
        ...this.variations.filter(
          (variation) => !groups.some((group) => group.uuid === variation.uuid)
        ),
      ];
    }
    //Senão todas as variações foram deletadas.
    else arrayRemoveds = this.variations;
    let promises = [];
    promises.push(
      arrayAdd.map(
        async (property) =>
          await ProductAPI.addProcuctPricing(productUuid, property)
      )
    );
    promises.push(
      arrayRemoveds.map(
        async (pricing) =>
          await ProductAPI.removeProcuctPricing(productUuid, pricing.uuid)
      )
    );

    promises.length > 0 &&
    (await this.handlePromises(promises, 'Falha ao desvincular categorias'));
  }

  /**Vincula produto em categorias. */
  async handleCategories(productUuid, categories = [], collections) {
    if (this.categoriesAdd.length > 0 || this.collectionsAdd.length > 0) {
      const data = [];
      this.categoriesAdd.map((e) => {
        data.push({
          uuid: e.uuid,
          '@class': 'br.com.stoom.sportbay.model.SportbayCategory'
        })
      })

      this.collectionsAdd.map((e) => {
        data.push({
          uuid: e.uuid,
          '@class': 'br.com.stoom.sportbay.model.SportbayCategory'
        })
      })

      if (categories && categories.length > 0) {
        categories.map((c) => {
          data.push(c);
        })
      }

      if (collections && collections.length > 0) {
        collections.map((c) => {
          data.push(c);
        })
      }

      await ProductAPI.addProductCategories(productUuid, data);
    }
    if (this.categoriesRemove.length > 0 || this.collectionsRemove.length > 0) {
      let promises = [];
      this.categoriesRemove = this.removeItensDuplicados(this.categoriesRemove);
      this.collectionsRemove = this.removeItensDuplicados(this.collectionsRemove);
      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(category.uuid, {
              uuid: productUuid,
            })
        ),
        this.collectionsRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(category.uuid, {
              uuid: productUuid,
            })
        ),
      ];
      promises.length > 0 &&
      (await this.handlePromises(promises, 'Falha ao desvincular categorias'));
    }
  }

  /**Busca todos os produtos */
  async getList() {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
    };
    const response = await ProductAPI.list(params);

    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos */
  async getListByType(productType = this.tabType) {
    this.loading = true;
    let search = '';

    if (this.filter)
      search = `name;*${this.filter}*`;
    if (this.filter_merchant_uuid)
      search = `merchant_uuid:${this.filter_merchant_uuid},${search}`;
    if (this.filter_brand_uuid)
      search = `brand_uuid:${this.filter_brand_uuid},${search}`;
    if (this.filter_active != undefined)
      search = `active:${this.filter_active},${search}`;
    if (this.filter_sku)
      search = `skuCode;*${this.filter_sku}*,${search}`;
    if (this.filter_approved)
      search = `approved:${this.filter_approved},${search}`;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      search: search,
    };

    const response = await ProductAPI.listByType(params, productType);

    if (!response.error && response.content.length >= 1) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else if(response.content.length < 1){
      this.products = []
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Produto não encontrado.");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }

  /**Busca todos os produtos */
  async listProductsForApproval(status, type) {
    this.loading = true;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
      sku: this.filter_sku,
      merchantUuid: this.filter_merchant_uuid
    };

    const response = await ProductAPI.listProductsForApproval(status, type, params);

    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async approveProductsList(uuids) {
    this.loadingApprove = true;
    const response = await ProductAPI.approveProductsList(uuids);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Produtos aprovados com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loadingApprove = false;
    return response;
  }

  async unapproveProductsList(uuids) {
    this.loadingApprove = true;
    const response = await ProductAPI.unapproveProductsList(uuids);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Produtos desaprovados com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loadingApprove = false;
    return response;
  }

  /**Quando selecionar uma tab faz a busca para setar no grid/tabela */
  async setTabContent(productType, sku) {
    console.log(productType)

    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 10;
    this.sort = 'created,desc';
    this.clearFilters();
    this.tabType = productType;

    switch (productType) {
      case 'APPROVAL':
        await this.listProductsForApproval('approval', 'model');
        break;
      case 'APPROVAL-SUB':
        await this.listProductsForApproval('approval', 'variation');
        break;
        break;
      case 'REPPROVAL':
        await this.listProductsForApproval('denied', 'model');
        break;
      case 'REPPROVAL-SUB':
        await this.listProductsForApproval('denied', 'variation');
        break;
      case 'LOWER-PRICE':

      break;

      default:
        await this.getListByType(productType);
    }
  }

  async getProductLowerPrice(){
    const sku = this. filter_sku

    this.loading = true

    const response = await ProductAPI.getProductsLowerPrice(sku);
    if (!response.error) {
      if(response.length > 0){
      this.products = await response.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      }else{
        this.products = []
      }

    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;

  }

  async getListBySku(skuCode) {
    this.loading = true;
    const params = {sku: skuCode};
    const response = await ProductAPI.getProductBySku(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza Produto */
  async update(categoryUUID) {
    this.loading = true;

    const categories = this.product.categories;
    this.product.categories = [];

    // const files = this.product.files;
    // if (files.length > 0) this.sendFiles(this.product.uuid, files);

    const data = JSON.stringify(this.product);

    const response = await ProductAPI.update(this.product.uuid, data);

    if (!response.error) {
      await this.handleCategories(this.product.uuid, categories);
      if (this.categoryFeatureValues.length > 0) {
        await this.updateProductFeatureValue(this.product.uuid, categoryUUID);
      }
      if (this.categoryFeatureValues.length === 0 && this.isInitialComparative) {
        await this.disableAllProductFeatureValue();
      }
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso, produto enviado para a aprovação!'
        ,
        3000
      );
    } else {
      // this.product.files = files;
      this.product.categories = categories;

      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Deleta produto */
  async delete(uuid) {
    this.loading = true;
    const response = await ProductAPI.delete(uuid);
    if (!response.error) {
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto deletado');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Desassocia a variação */
  async disableVariation(variationUuid) {
    const response = await ProductAPI.disableVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação desabilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Desassocia a variação */
  async activateVariation(variationUuid) {
    const response = await ProductAPI.activateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação habilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca categorias do produto */
  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new SportbayCategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = 'uuid') {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new SportbayProductModel(response);
      product.categories = await this.getCategories(product.uuid);
      this.product =
        product.prices.length === 0
          ? new SportbayProductModel({
            ...product,
            prices: this.createNewPrices(),
          })
          : new SportbayProductModel(product);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(productUuid, files) {
    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter(
      (file) => file.uuid && file.localModified && file.file
    );
    const deleteds = files.filter(
      (file) => file.uuid && !file.file && file.localModified
    );

    newFiles.length > 0 && (await this.sendNewFiles(newFiles, productUuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    deleteds.length > 0 && (await this.deleteFiles(deleteds));
  }

  /**Funcão útil para dar feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.handlePromises(promise, 'Falha o deletar arquivo');
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      'Falha atualizar arquivos.'
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async sendNewFiles(newFiles, productUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadProduct(productUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  /**Retorna lista para uso no select */
  getListSelect(products = this.products) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: prd.name,
    }));
  }

  /**Retorna lista de categoria para uso no select */
  getListCategorySelect(categories) {
    const optionsCategory = [{label: 'Não possui comparativo', value: false}]
    if (!categories) {
      return optionsCategory;
    } else {
      categories.map((ctg) => {
        optionsCategory.push({
          value: ctg.uuid,
          label: ctg.categoryName,
        })
      });
      return optionsCategory;
    }
  }

  changeToApplicationsSelect(app) {
    return app.map((m) => ({value: m.uuid, label: m.getNameSelect}));
  }

  async checkCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.checkCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async sendCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.sendCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    if (response[1].data.sucess) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Atualizado com sucesso');
    }
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async getCsvProducts() {
    this.loading = true;
    let response = await UploadAPI.getProductsCSV();
    if (!response.error) {
      this.loading = false;
      return response.data;
    }
    this.loading = false;
    return;
  }

  /**Adiciona Propriedade no product */
  addPropertieSelected(propertie) {
    this.product.productProperties.push(propertie);
  }

  /**Remove a propriedade selecionada */
  removePropertieSelected(element) {
    this.loading = true;
    const productProperties = this.product.productProperties.filter(
      (e) => e.uuid !== element.uuid
    );
    this.product.productProperties = productProperties;
    this.loading = false;
  }

  /**Adiciona Parte/Produto no itemsCombo */
  addComboSelected(partProduct) {
    this.product.itemsCombo.push(partProduct);
  }

  /**Remove a Parte/Produto no itemsCombo */
  removeComboSelected(element) {
    this.loading = true;
    const itemsCombo = this.product.itemsCombo.filter(
      (e) => e.product.uuid !== element.product.uuid
    );
    this.product.itemsCombo = itemsCombo;
    this.loading = false;
  }

  //**Valida se o produto possui categoria ativa no cadastro */
  checkProductHasCategory() {
    if (this.product.categories && this.product.categories.length > 0) {
      if (this.categoriesRemove.length === this.product.categories.length) {
        if (this.categoriesAdd.length > 0) {
          return true;
        } else {
          return false;
        }
      } else return true;
    } else if (this.categoriesAdd.length > 0) {
      return true;
    }

    return false;
  }

  handleChangeImageModel(path) {
    const product = this.product;
    const metaTags = [
      "card"
    ]

    let file = {path, metaTags, size: 1, name: product.name ? product.name + uuidv4() : uuidv4(), ordering: 1};
    file = new SportbayFileModel(file);

    if (product.files && product.files.length > 0) {
      product.files = [file]
    } else if (product.files && product.files.length === 0) {
      product.files.push(file);
    }

    this.product = new SportbayProductModel(product);
  }

  // Deixa a variação no padrão do model
  handleTransformProductVariation(product) {
    const transformProduct = new SportbayProductModel(product);
    return transformProduct;
  }

  // Remove uma variação dentro do produto modelo
  removeProductVariation(variation) {
    if (!isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = this.product.productVariations.filter(
        (f) => f.uuid !== variation.uuid
      );

      this.product = new SportbayProductModel(this.product);

      this.groupPropertyClassOfVariations(variation, true);
    }
  }

  // Adiciona uma variação no modelo
  addProductVariation(variation) {
    if (isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = [];
    }

    const alreadyExists = this.product.productVariations.filter(
      (f) => f.uuid === variation.uuid
    )[0];

    if (!alreadyExists) {
      this.product.productVariations.push(variation);
      this.product = new SportbayProductModel(this.product);
    }

    this.groupPropertyClassOfVariations(variation);
  }

  groupPropertyClassOfVariations(variation, isDelete = false) {
    let propertyClassses = [];

    if (isArrayEmpty(this.product.productVariations)) {
      this.product.productVariations = [];
    }

    if (isArrayEmpty(this.variationsAttributesGrouped)) {
      this.variationsAttributesGrouped = [];
    }

    if (!isArrayEmpty(variation.productProperties)) {
      variation.productProperties.forEach((property) => {
        propertyClassses.push(property.propertyClass);
      });
    }

    if (isDelete) {
      propertyClassses.forEach((propertyClass) => {
        // se ainda existir variação com a property class proposta a removoer, não remove
        const yetExistsVariation = this.product.productVariations.some((f) =>
          f.productProperties.some(
            (s) => s.propertyClass.uuid === propertyClass.uuid
          )
        );

        if (yetExistsVariation) {
          propertyClass.needToRemove = false;
        } else {
          propertyClass.needToRemove = true;
        }
      });

      const propertyClassesToRemove = propertyClassses.filter(
        (f) => f.needToRemove
      );

      this.variationsAttributesGrouped = this.variationsAttributesGrouped.filter(
        (f) => !propertyClassesToRemove.some((s) => s.uuid === f.uuid)
      );
    } else {
      this.variationsAttributesGrouped = [
        ...this.variationsAttributesGrouped,
        ...propertyClassses,
      ].filter((f, index, self) => onlyUniqueByProp(f, index, self, 'uuid'));
    }
  }

  imageNextOrdering(files) {
    let order = 0;
    files.forEach((item) => {
      if (order < item.ordering)
        order = item.ordering;
    });

    return order + 1;
  }

  // Adiciona Imagem na variação
  handleAddImageVariation(path) {
    let validate = validateImgLink(path)
    if (validate) {
      const product = this.product;
      const metaTags = [
        "card"
      ];

      let file = {
        path,
        metaTags,
        size: 1,
        ordering: this.imageNextOrdering(product.files),
        name: product.name ? product.name + uuidv4() : uuidv4()
      };

      file = new SportbayFileModel(file);

      product.files.push(file);

      this.product = new SportbayProductModel(product);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'URL invalida')
    }
  }

  handleRemoveImageVariation(index) {
    const product = this.product;
    const files = [];

    product.files.map((f, i) => {
      if (index !== i) {
        files.push(new SportbayFileModel(f));
      }
    });

    product.files = files;

    this.product = new SportbayProductModel(product);
  }

  /**Retorna variações filtradas de acordo com o term */
  async getAvailableProductVariations(term) {
    const response = await ProductAPI.getAvailableProductVariations(term);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Desassocia a variação */
  async disassociateVariation(variationUuid) {
    const response = await ProductAPI.disassociateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Variação desassociada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca os comparativos da categoria selecionada pelo usuário */
  async listCategoryFeatures(categoryUuid) {

    if (!categoryUuid) {
      this.categoryFeatureValues = [];
      this.featureInProduct = false;
      return [];
    } else {
      this.loadingSearchCategoryFeature = true;
      const response = await ProductAPI.listCategoryFeatures(categoryUuid);
      if (!response.error) {
        if (response.length > 0) {
          const categoryFeatureValues = []
          response.map((ft) => {
            categoryFeatureValues.push(new SportbayProductComparativeModel(
              {
                uuid: ft.categoryFeatureUUID,
                name: ft.feature,
                type: ft.type,
                value: ft.type === 'TEXT' ? '' : false,
              }
            ));
          })
          this.categoryFeatureValues = categoryFeatureValues;
        } else {
          this.categoryFeatureValues = [];
        }
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
      this.loadingSearchCategoryFeature = false;
      this.initialFeatureValue = true;
      this.featureInProduct = true;
      return response;
    }
  }

  /**Altera o valor de um input ou select dentro do array de categoryFeatureValues */
  handleChangeCategoryFeatureValue(value, index) {
    let categoryFeatureValues = this.categoryFeatureValues;

    categoryFeatureValues[index].value = value;

    this.categoryFeatureValues = categoryFeatureValues;
  }

  async setCategoryFeatureValue(uuidProduct) {
    const response = await ProductAPI.listProductFeature(uuidProduct);
    if (!response.error) {
      this.categoryFeatureValues = response.data;
      if (response.data.length > 0) {
        this.initialFeatureValue = false;
        this.isInitialComparative = true;
        const categoryFeaturesInitial = [];
        response.data.map((ctg) => {
          categoryFeaturesInitial.push(ctg.categoryFeatureUUID)
        })
        this.categoryFeatureInitial = categoryFeaturesInitial;
      }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  async saveProductFeatureValue(productUUID) {
    const data = {
      productUUID,
      categoryFeatureValues: this.categoryFeatureValues,
    };

    let response = await ProductAPI.saveProductFeatureValue(data);

    if (!response.error) {
      return response;
    } else {
      return 'Falha ao salvar atributos para comparativo';
    }
  }

  async updateProductFeatureValue(productUUID, categoryUUID) {
    let categoryFeatureValues = [];

    if (this.initialFeatureValue) {
      categoryFeatureValues = this.categoryFeatureValues;
    } else {
      this.categoryFeatureValues.map((ft) => {
        categoryFeatureValues.push({
          productFeatureUUID: ft.uuid,
          uuid: ft.categoryFeatureUUID,
          value: ft.value
        });
      })
    }

    const data = {
      productUUID,
      categoryUUID,
      categoryFeatureValues: categoryFeatureValues,
    };

    let response = await ProductAPI.updateProductFeatureValue(data);

    if (!response.error) {
      return response;
    } else {
      return 'Falha ao salvar atributos para comparativo';
    }
  }

  async disableAllProductFeatureValue() {
    const categoryFeatureValues = [];

    this.categoryFeatureInitial.map((uuid) => {
      categoryFeatureValues.push({uuid})
    });

    const data = {
      categoryFeatureValues: categoryFeatureValues
    }

    let response = await ProductAPI.disableAllFeatureByProductValue(this.product.uuid, data);

    if (!response.error) {
      return response;
    } else {
      return 'Falha ao deletar os atributos para comparativo';
    }
  }

  async getProductVariations(name) {
    const response = await ProductAPI.getProductVariations(name);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  async inactiveAllVariations(uuid) {
    if(uuid){
      this.inactiveProductsLoading = true
      const response = await ProductAPI.inactiveAllProductsVariations(uuid)
      if(!response.error){
        await this.get(uuid)
        this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Produtos inativos com sucesso.');
      }else{
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao inativar Produtos');
      }
      this.inactiveProductsLoading = false
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao inativar Produtos');
    }
  }


  async activeAllVariations(uuid) {
    if(uuid){
      this.activeProductsLoading = true
      const response = await ProductAPI.activeAllProductsVariation(uuid)
      if(!response.error){
        await this.get(uuid)
        this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Produtos ativos com sucesso.');
      }else{
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao ativar Produtos');
      }
      this.activeProductsLoading = false
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao ativar Produtos');
    }
  }
  async AddFreeShipping(data,merchant){
    if(merchant.label === "Sportbay"){
    const response = await ProductAPI.addFreeShipping(data,merchant.value)
      if(!response.error){
        return response
      }else{
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao ativar frete aos Produtos');
      }
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Erro ao ativar frete aos Produtos');
    }
  }
}

export default ProductStore;

decorate(ProductStore, {
  getList: action,
  createEmptyProduct: action,
  searchByName: action,
});
