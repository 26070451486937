import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import LoadingComponent from '~/components/LoadingComponent/LoadingComponent';
import ChatWidget from './components/ChatWidgetCComponent/ChatWidget';

//Lista de Usuarios com permissão de acesso

const permission = [
  {name: "Sportbay", uuid:"81d5d890-35e6-42e9-980f-51ee01520d64"}
]

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import('~/pages/HomePage/HomePage'));
const NotFound = lazy(() => import('~/pages/NotFound/NotFound'));
const LoginCenterPage = lazy(() =>
  import('~/pages/LoginCenterPage/LoginCenterPage')
);

const UpdateCSVContainer = lazy(() =>
  import('~/containers/UpdateCSVContainer')
);

//Componenentes de acordo ao item de menu
const DashboardsContainer = lazy(() =>
  import('~/containers/DashboardsContainer')
);
const ProductCreateContainer = lazy(() => import('~/containers/ProductCreateContainer'));
const ProductUpdateContainer = lazy(() => import('~/containers/ProductUpdateContainer'));
const CustomerContainer = lazy(() => import('~/containers/CustomerContainer'));
const AdministratorContainer = lazy(() =>
  import('~/containers/AccountContainer')
);
const MerchantContainer = lazy(() => import('~/containers/MerchantContainer'));
const SupplierContainer = lazy(() => import('~/containers/SupplierContainer'));
const ApplicationContainer = lazy(() =>
  import('~/containers/ApplicationContainer')
);
const AutomakerContainer = lazy(() =>
  import('~/containers/AutomakerContainer')
);
const PropertyGroupContainer = lazy(() =>
  import('~/containers/PropertyGroupContainer')
);
const PropertyClassContainer = lazy(() =>
  import('~/containers/PropertyClassContainer')
);
const CategoryContainer = lazy(() => import('~/containers/CategoryContainer'));
const PreOrderContainer = lazy(() => import('~/containers/PreOrderContainer'));
const ShippingServiceContainer = lazy(() =>
  import('~/containers/ShippingServiceContainer')
);
const ShippingClassContainer = lazy(() =>
  import('~/containers/ShippingClassContainer')
);
const HomeStoreContainer = lazy(() =>
  import('~/containers/HomeStoreContainer')
);
const InstitutionalContainer = lazy(() =>
  import('~/containers/InstitutionalContainer')
);
const CouponContainer = lazy(() =>
  import('~/containers/CouponContainer')
)
const ManagerPreOrderContainer = lazy(() =>
  import('~/containers/ManagerPreOrderContainer')
);
const ManagerProductsContainer = lazy(() =>
  import('~/containers/ManagerProductContainer')
);
const ManagerCategoryContainer = lazy(() =>
  import('~/containers/ManagerCategoryContainer')
);
const ManagerSupplierContainer = lazy(() =>
  import('~/containers/ManagerSupplierContainer')
);
const ManagerApplicationContainer = lazy(() =>
  import('~/containers/ManagerApplicationContainer')
);
const ManagerAutomakerContainer = lazy(() =>
  import('~/containers/ManagerAutomakerContainer')
);
const ManagerAccountContainer = lazy(() =>
  import('~/containers/ManagerAccountContainer')
);
const ManagerMerchantContainer = lazy(() =>
  import('~/containers/ManagerMerchantContainer')
);
const ManagerClientContainer = lazy(() =>
  import('~/containers/ManagerCustomerContainer')
);
const ManagerPropertiesContainer = lazy(() =>
  import('~/containers/ManagerPropertiesContainer')
);
const ManagerOrderContainer = lazy(() =>
  import('~/containers/ManagerOrderContainer')
);
const ManagerShippingServiceContainer = lazy(() =>
  import('~/containers/ManagerShippingServiceContainer')
);
const ManagerShippingClassContainer = lazy(() =>
  import('~/containers/ManagerShippingClassContainer')
);
const ManagerZipCodeRangeContainer = lazy(() =>
  import('~/containers/ManagerZipCodeRangeContainer')
);
const ZipCodeRangeContainer = lazy(() =>
  import('~/containers/ZipCodeRangeContainer')
);
const ManagerInstitutionContainer = lazy(() =>
  import('~/containers/ManagerInstitutionContainer')
);
const ManagerBannerContainer = lazy(() =>
  import('~/containers/ManagerBannerContainer')
);
const ManagerCouponContainer = lazy(() =>
  import('~/containers/ManagerCouponContainer')
)
const OrderDetailContainer = lazy(() =>
  import('~/containers/OrderDetailContainer')
);

const ComparativeContainer = lazy(() =>
  import('~/containers/ComparativeContainer')
);
const KitCreateContainer = lazy(() => import('~/containers/KitCreateContainer'));
const KitUpdateContainer = lazy(() => import('~/containers/KitUpdateContainer'));

const BannerCreateContainer = lazy(() => import('~/containers/BannerCreateContainer'));
const BannerUpdateContainer = lazy(() => import('~/containers/BannerUpdateContainer'));

const ManagerKitContainer = lazy(() =>
  import('~/containers/ManagerKitContainer')
);

const ManagerComparativeContainer = lazy(() =>
  import('~/containers/ManagerComparativeContainer')
);

const ConnectComparativeContainer = lazy(() =>
  import('~/containers/ConnectComparativeContainer')
);

const ManagerAssociateContainer = lazy(() =>
  import('~/containers/ManagerAssociateContainer')
);

const UpdateComparativeContainer = lazy(() =>
  import('~/containers/ComparativeUpdateContainer')
);

const BrandCreateContainer = lazy(() =>
  import('~/containers/BrandCreateContainer')
);

const BrandUpdateContainer = lazy(() =>
  import('~/containers/BrandUpdateContainer')
);

const ManagerBrandContainer = lazy(() =>
  import('~/containers/ManagerBrandContainer')
);

const NewsLetterContainer = lazy(() =>
  import('~/containers/NewsLetterContainer')
);

const NewsLetterEditorContainer = lazy(() =>
  import('~/containers/NewsLetterEditorContainer')
);

const ManagerSalesContainer = lazy(() =>
  import('~/containers/ManagerSalesContainer')
);

const AuctionProductCreateContainer = lazy(()=>
import('~/containers/AuctionCreateContainer'));

const AuctionListProductContainer = lazy(()=>
  import('~/containers/AuctionListContainer')
)

const AuctionComponent = lazy(()=>
  import('~/containers/AuctionContainer')
)

const AuctionStatusComponent = lazy(()=>
  import('~/containers/AuctionStatusContainer')
)
const ManageFreeShipping = lazy(()=>
  import('~/containers/ManageFreeShipping')
)
const ManagerFreightSimulation = lazy(()=>
  import('~/containers/ManagerFreightSimulation')
)
const ManagerCreatTransfer = lazy(()=>
  import('~/containers/ManagerCreatTranfer')
)


//Rotas globais de nossa aplicação manager.
export default inject(({ authStore, usersStore }) => ({ authStore, usersStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push('/'));
      };

      const auth = permission.some(item => item.uuid === props.usersStore?._user?.merchant?.uuid)
      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/error-page" component={NotFound} />
            <Route exact path="/login" render={() => <Redirect to={'/'} />} />
            <Route exact path="/logout" render={() => logout()} />
            <PrivateRoute
              path="/"
              render={() => <HomePage auth={auth} />}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = (props) => {
  const { auth } = props;

  return (
  <Switch>
    <Route exact path="/update-csv/:type" component={auth ? UpdateCSVContainer : NotFound} />

    <Route exact path="/cadastrar-clientes" component={auth ? CustomerContainer : NotFound} />
    <Route exact path="/cadastrar-produtos" component={auth ? ProductCreateContainer : NotFound} />
    <Route exact path="/cadastrar-marcas" component={auth ? BrandCreateContainer : NotFound} />
    <Route
      exact
      path="/cadastrar-propriedade"
      component={auth ? PropertyClassContainer : NotFound}
    />
    <Route exact path="/cadastrar-categoria" component={auth ? CategoryContainer : NotFound} />
    <Route exact path="/cadastrar-fornecedor" component={auth ? SupplierContainer : NotFound} />
    <Route exact path="/cadastrar-montadora" component={auth ? AutomakerContainer : NotFound} />
    <Route exact path="/cadastrar-aplicacao" component={auth ? ApplicationContainer : NotFound} />
    <Route exact path="/cadastrar-merchant" component={auth ? MerchantContainer : NotFound} />
    <Route exact path="/cadastrar-comparativo" component={auth ? ComparativeContainer : NotFound} />
    <Route exact path="/comparativos" component={auth ? ManagerComparativeContainer : NotFound} />
    <Route exact path="/gerenciar-associados" component={auth ? ManagerAssociateContainer : NotFound} />
    <Route exact path="/gerenciar-home-store" component={auth ? HomeStoreContainer : NotFound} />
    <Route exact path="/cadastrar-kit" component={auth ? KitCreateContainer : NotFound} />
    <Route exact path="/cadastrar-banner" component={auth ? BannerCreateContainer : NotFound} />
    <Route exact path="/cadastrar-cupom" component={CouponContainer} />
    <Route
      exact
      path="/cadastrar-administrador"
      component={auth ? AdministratorContainer : NotFound}
    />
    <Route
      exact
      path="/cadastrar-servico"
      component={auth ? ShippingServiceContainer : NotFound}
    />
    <Route
      exact
      path="/cadastrar-transportadora"
      component={auth ? ShippingClassContainer : NotFound}
    />
    <Route
      exact
      path="/cadastrar-forma-envio"
      component={auth ? ShippingServiceContainer : NotFound}
    />
    <Route
      exact
      path="/cadastrar-institucional"
      component={auth ? InstitutionalContainer : NotFound}
    />

    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={auth ? CustomerContainer: NotFound}
    />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductUpdateContainer}
    />
    <Route
      exact
      path="/editar-propriedade/:classUuid"
      component={auth ? PropertyGroupContainer : NotFound}
    />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={auth ? CategoryContainer : NotFound}
    />
    <Route
      exact
      path="/editar-fornecedor/:uuidSupplier"
      component={auth ? SupplierContainer : NotFound}
    />
    <Route
      exact
      path="/editar-montadora/:uuidAutomaker"
      component={auth ? AutomakerContainer : NotFound}
    />
    <Route
      exact
      path="/editar-aplicacao/:uuidApplication"
      component={auth ? ApplicationContainer : NotFound}
    />
    <Route
      exact
      path="/editar-merchant/:uuidMerchant"
      component={auth ? MerchantContainer : NotFound}
    />
    <Route
      exact
      path="/editar-administrador/:uuidAdministrator"
      component={AdministratorContainer}
    />
    <Route
      exact
      path="/editar-kit/:uuidKit"
      component={auth ? KitUpdateContainer : NotFound}
    />
    <Route exact path="/editar-cupom/:uuidCoupon" component={CouponContainer} />
    <Route
      exact
      path="/editar-banner/:uuidBanner"
      component={auth ? BannerUpdateContainer : NotFound}
    />
    <Route
      exact
      path="/editar-comparativo/:uuidComparative"
      component={auth ? UpdateComparativeContainer : NotFound}
    />
    <Route
      exact
      path="/editar-associados/:uuidComparative"
      component={auth ? ConnectComparativeContainer : NotFound}
    />
    <Route
      exact
      path="/detalhes-pedido/:uuidOrder/user/:uuidUser"
      component={OrderDetailContainer}
    />
    <Route
      exact
      path="/editar-servico/:uuidShipping"
      component={auth ? ShippingServiceContainer : NotFound}
    />
    <Route
      exact
      path="/editar-transportadora/:uuidShipping"
      component={auth ? ShippingClassContainer : NotFound}
    />
    <Route
      exact
      path="/detalhes-orcamento/:uuidPreOrder"
      component={auth ? PreOrderContainer : NotFound}
    />
    <Route
      exact
      path="/editar-institucional/:uuidInstitutional"
      component={auth ? InstitutionalContainer : NotFound}
    />

    <Route
      exact
      path="/editar-marca/:uuidBrand"
      component={auth ? BrandUpdateContainer : NotFound}
    />

    <Route exact path="/clientes" component={auth ? ManagerClientContainer : NotFound} />
    <Route exact path="/produtos" component={ManagerProductsContainer} />
    <Route exact path="/propriedades" component={auth ? ManagerPropertiesContainer : NotFound} />
    <Route exact path="/categorias" component={auth ? ManagerCategoryContainer : NotFound} />
    <Route exact path="/fornecedores" component={auth ? ManagerSupplierContainer : NotFound} />
    <Route exact path="/montadoras" component={auth ? ManagerAutomakerContainer : NotFound} />
    <Route exact path="/aplicacoes" component={auth ? ManagerApplicationContainer : NotFound} />
    <Route exact path="/merchants" component={auth ? ManagerMerchantContainer : NotFound} />
    <Route exact path="/administradores" component={auth ? ManagerAccountContainer : NotFound} />
    <Route exact path="/pedidos" component={ManagerOrderContainer} />
    <Route exact path="/orcamentos" component={auth ? ManagerPreOrderContainer : NotFound} />
    <Route exact path="/kits" component={auth ? ManagerKitContainer : NotFound} />
    <Route exact path="/banners" component={auth ? ManagerBannerContainer : NotFound} />
    <Route exact path="/cupons" component={ManagerCouponContainer} />
    <Route exact path="/marcas" component={auth ? ManagerBrandContainer : NotFound} />
    <Route exact path="/newsletters" component={auth ? NewsLetterContainer : NotFound} />
    <Route path="/newsletter/:id?" component={auth ? NewsLetterEditorContainer : NotFound} />
    <Route
      exact
      path="/transportadoras"
      component={auth ? ManagerShippingClassContainer : NotFound}
    />
    <Route
      exact
      path="/faixas-frete"
      component={auth ? ManagerZipCodeRangeContainer : NotFound}
    />
    <Route
      exact
      path="/cadastrar-faixa-frete"
      component={auth ? ZipCodeRangeContainer : NotFound}
    />
    <Route
      exact
      path="/editar-faixa-frete/:uuidZipCodeRange"
      component={auth ? ZipCodeRangeContainer : NotFound}
    />
    <Route exact path="/servicos" component={auth ? ManagerShippingServiceContainer : NotFound} />
    <Route
      exact
      path="/gerenciar-institucionais"
      component={auth ? ManagerInstitutionContainer : NotFound}
    />
    <Route
      exact
      path="/orcamentos-pendentes"
      render={() =>
        auth ? (
          <ManagerPreOrderContainer
            title="Orçamentos pendentes"
            pendents
            subtitle="Orçamentos que estão aguardando análise"
          />
        ) : (
          <NotFound />
        )
      }
    />
    <Route
      exact
      path="/orcamentos-analise"
      render={() =>
        auth ? (
          <ManagerPreOrderContainer
            title="Orçamentos em análise"
            analysis
            subtitle="Orçamentos que estão em análise"
          />

          ) : (
            <NotFound />
          )}
    />
    <Route
      exact
      path="/orcamentos-liberado"
      render={() =>
        auth ? (
          <ManagerPreOrderContainer
            title="Orçamentos liberados para o cliente"
            cart
            subtitle="Orçamentos liberados para o cliente analisar"
          />
        ) : (
          <NotFound />
        )}
    />
    <Route
      exact
      path="/orcamentos-analise-frete"
      render={() =>
        auth ? (
          <ManagerPreOrderContainer
            title="Análise de frete"
            shippingAnalisys
            subtitle="Orçamentos que estão em análise de frete"
          />
          ) : (
            <NotFound />
          )}
    />
    <Route exact path="/" component={DashboardsContainer} />
    <Route exact path="/vendas/general" component={auth ? ManagerSalesContainer : NotFound} />
    <Route exact path="/cadastrar-leilao" component={auth ? AuctionProductCreateContainer : NotFound} />
    <Route exact path="/leilao" component={auth ? AuctionListProductContainer : NotFound} />
    <Route exact path="/editar-leilao/:uuidAuction" component={auth ? AuctionComponent : NotFound} />
    <Route exact path="/leilao/status" component={auth ? AuctionStatusComponent : NotFound} />
    <Route exact path="/frete-gratis" component={auth ? ManageFreeShipping : NotFound} />
    <Route exact path='/simular-frete' component={auth ? ManagerFreightSimulation : NotFound} />
    <Route exact path='/cadatrar-trasferencia' component={auth ? ManagerCreatTransfer : NotFound} />
  </Switch>
  )
};

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ render: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <>
          <Component {...props} />
          <ChatWidget />
        </>
      ) : (
        <LoginCenterPage {...props} />
      )
    }
  />
);
