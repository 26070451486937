/* eslint-disable no-console */
import BaseAPI, {URLS} from './BaseAPI';

class OrderAPI {
  static _exception(e, message) {
    console.log(e);
    return {error: message};
  }

  /*
    *Cria um novo pedido
    *@param {} -
    ****/
  static async create(preOderUuid, order) {
    try {
      const url = `${URLS.PRE_ORDERS}/${preOderUuid}/orders`;
      const response = await BaseAPI.post(url, order);
      if (response.status === 201) return response;
      return {error: 'Falha o criar pedido'};
    } catch (e) {
      return this._exception(e, 'Falha ao criar pedido');
    }
  }

  /**
   *@description Adiciona items ao pedido
   *@param {userUuid} userUuid identificador do usuário
   *@param {orderUuid} orderUuid identificador do pedido
   *@param {data} items que serão adicionados ao carrinho.
   @PostMapping("/{user_uuid}/orders/{order_uuid}/products")
   */
  static async addItems(userUuid, orderUuid, data) {
    try {
      const url = `customers/${userUuid}/orders/${orderUuid}/products`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Erro ao adiocionar produto'};
    } catch (e) {
      return this._exception(e, 'Erro ao adicionar produto no pedido.');
    }
  }

  /**
   *@description Altera campo observação do orderItem
   *@param {number} orderUuid identificador do pedido
   *@param {number} itemUuid identificador do itemPedido
   *@param {string} textObservation texto Observação.
   @PostMapping("/order/{order_uuid}/items/{item_uuid}/observation")
   */
  static async changeObservationItem(orderUuid, itemUuid, textObservation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/items/${itemUuid}/observation`;
      const response = await BaseAPI.post(url, {observation: textObservation});
      if (response.status === 200) return response.data;
      return {error: 'Falha ao inserir texto observação'};
    } catch (e) {
      return this._exception(e, 'Falha ao inserir texto observação.');
    }
  }

  /**
   *@description Altera campo observação do orderItem
   *@param {number} orderUuid identificador do pedido
   *@param {string} textObservation texto Observação.
   @PostMapping("/order/{order_uuid}/observation")
   */
  static async changeObservation(orderUuid, textObservation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/observation`;
      const response = await BaseAPI.post(url, {observation: textObservation});
      if (response.status === 200) return response.data;
      return {error: 'Falha ao inserir texto observação'};
    } catch (e) {
      return this._exception(e, 'Falha ao inserir texto observação.');
    }
  }

  /**
   *@description remove items do carrinho.
   *@param {userUuid} userUuid identificador do usuário
   *@param {orderUuid} orderUuid identificador do pedido
   *@param {data} items que serão adicionados ao carrinho.
   @PostMapping("/{user_uuid}/orders/{order_uuid}/products")
   */
  static async removeItems(userUuid, orderUuid, data) {
    try {
      const url = `customers/${userUuid}/orders/${orderUuid}/products`;
      const response = await BaseAPI.delete(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Erro ao remover produto'};
    } catch (e) {
      return this._exception(e, 'Erro ao remover produto no pedido.');
    }
  }

  /**
   * Busca pedidos de um usuário
   * @param {orderUuid} orderUuid - uuid do pedido
   * @param {userUuid} uuid - uuid do usuário
   * */
  static async getOrderUser(orderUuid, userUuid) {
    try {
      const url = `${URLS.CUSTOMERS}/${userUuid}/ordersmerchant/${orderUuid}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {error: 'Ocorreu um erro ao buscar o pedido!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }

  /**
   * Busca pedidos de um usuário
   * @param {orderUuid} orderUuid - uuid do pedido
   * @param {userUuid} uuid - uuid do usuário
   * */
  static async getOrderUserMKT(orderUuid, userUuid) {
    try {
      const url = `${URLS.CUSTOMERS}/${userUuid}/ordersmerchant/${orderUuid}`;
      const response = await BaseAPI.get(url);

      if (response.status === 200) return response.data;
      return {error: 'Ocorreu um erro ao buscar o pedido!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }


  /**
   * Atualiza a observação do pedido.
   * @param {orderUuid} orderUuid - uuid do pedido.
   * @param {observation} observation - observação que será atualizada no pedido.
   * */
  static async updateObservation(orderUuid, observation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/observation`;
      const response = await BaseAPI.patch(url, observation);

      if (response.status === 200) return response.data;

      return {error: 'Ocorreu um erro ao atualizar a observação do pedido!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }

  /**
   * @description Altera statuso do pedido.
   * @param {userUuid} userUuid identificador do usuário
   * @param {orderUuid} orderUuid identificador do pedido
   * @param {OrderStatusModel} data objeto do novo status.
   * @PutMapping("/{user_uuid}/orders/{order_uuid}/status")
   * */
  static async changeOrderStatus(userUuid = 'user', orderUuid, data) {
    try {
      ///customers/{user_uuid}/orders/{order_uuid}/status
      // const url = `${URLS.CUSTOMERS}/${userUuid}/orders/${orderUuid}/status`;
      const url = `${URLS.CUSTOMERS}/${userUuid}/ordersmerchant/${orderUuid}/status`;
      const response = await BaseAPI.put(url, data);
      if (response.status === 200 || response.status === 303) return response.data;
      return {error: 'Erro ao alterar status do pedido'};
    } catch (e) {
      return this._exception(e, 'Erro ao alterar status do pedido.');
    }
  }

  /**
   * Busca imagem do produto pelas metatags e products
   * @param  {string} orderUuid -Uuid do pedido
   * @param  {Array} metaTags - tags do pedido.
   */
  static async getFile(orderUuid, metaTags) {
    try {
      const array = Array.isArray(metaTags) ? metaTags : [metaTags];
      const url = `orders/${orderUuid}/${URLS.FILES}/`;
      const response = await BaseAPI.get(url, array);
      if (response.status === 200) return response.data;
      return {error: `${response.status === 404 ? 'Pedido naõ encontrado' : 'falha ao buscar imagem do pedido'}`};
    } catch (e) {
      return this._exception(e, 'Falha ao buscar pedido');
    }
  }

  /**
   * Busca lista de ultimos pedidos
   * @param {params} pageable - parametros de paginação
   * */
  static async list(params) {
    try {
      const response = await BaseAPI.get(URLS.SEARCHORDERS, params);
      if (response.status === 200) return response.data;
      return {error: 'Ocorreu um erro ao buscar lista de pedidos!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }

  /**
   * Busca lista de ultimos pedidos
   * @param {params} pageable - parametros de paginação
   * */
  static async listMKT(params) {
    try {
      const response = await BaseAPI.get(URLS.SEARCHORDERSMERCHANTS, params);
      if (response.status === 200) return response.data;
      return {error: 'Ocorreu um erro ao buscar lista de pedidos!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }


  /**
   * Busca pedidos de um usuário
   * @param {params} pageable - parametros de paginação
   * @param {userUuid} uuid - uuid do usuário
   * */
  static async listUser(userUuid, params) {
    try {
      const url = `${URLS.CUSTOMERS}/${userUuid}/ordersmerchant`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return {error: 'Ocorreu um erro ao buscar lista de pedidos!'};
    } catch (e) {
      console.log(e);
      return {error: e};
    }
  }

  /**
   *@description Adiciona items recomendados ao pedido
   *@param {orderUuid} orderUuid identificador do pedido
   *@param {object} data Lista de produtos recomendados.
   @PostMapping("/orders/{order_uuid}/recommended")
   */
  static async addProductRecomended(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Erro ao adiocionar produto recomendado'};
    } catch (e) {
      return this._exception(e, 'Erro ao adicionar produto recomendado.');
    }
  }

  /**
   *@description remove items recomendados do pedido.
   *@param {orderUuid} orderUuid identificador do pedido
   *@param {data} items lista de items que serão removidos do pedido.
   @DeleteMapping("/orders/{order_uuid}/recommended")
   */
  static async removeRecomendedItems(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended`;
      const response = await BaseAPI.delete(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Erro ao remover produto'};
    } catch (e) {
      return this._exception(e, 'Erro ao remover produto no pedido.');
    }
  }

  /**
   *@description Altera campo observação do recomendadeItem
   *@param {number} orderUuid identificador do pedido
   *@param {number} itemUuid identificador do itemPedido
   *@param {string} textObservation texto Observação.
   @PostMapping("/orders/{order_uuid}/recommended/{recommended_uuid}/observation")
   */
  static async changeObservationRecomended(orderUuid, recommendedUuid, textObservation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended/${recommendedUuid}/observation`;
      const response = await BaseAPI.post(url, {observation: textObservation});
      if (response.status === 200) return response.data;
      return {error: 'Falha ao inserir texto observação'};
    } catch (e) {
      return this._exception(e, 'Falha ao inserir texto observação.');
    }
  }

  /**
   *@description Altera campo observação do recomendadeItem
   *@param {number} orderUuid identificador do pedido
   *@param {number} itemUuid identificador do itemPedido
   *@param {number} amount texto Observação.
   @PostMapping("/orders/{order_uuid}/recommended/{recommended_uuid}/amount")
   */
  static async changeAmountRecomended(orderUuid, recommendedUuid, amount) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended/${recommendedUuid}/amount`;
      const response = await BaseAPI.post(url, {amount});
      if (response.status === 200) return response.data;
      return {error: 'Falha ao inserir texto observação'};
    } catch (e) {
      return this._exception(e, 'Falha ao inserir texto observação.');
    }
  }

  /**
   *@description Seta os tipos de frete para aquele serviço
   *@param {string} orderUuid identificador do pedido
   *@param {data} data shipping options list.
   @PostMapping("{{base_url}}/manager/orders/{{order_uuid}}/shipping/options")
   */
  static async setShippingOptions(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/shippings/options`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Falha ao inserir novos tipos de entregas.'};
    } catch (e) {
      return this._exception(e, 'Falha ao inserir novos tipos de entregas.');
    }
  }

  static async changeImporter(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}`;
      const response = await BaseAPI.patch(url, data);
      if (response.status === 200) return response.data;
      return {error: 'Falha atualizar o pedido.'};
    } catch (e) {
      return this._exception(e, 'Falha atualizar o pedido.');
    }
  }

  /**
   *@description Consulta o relatório de vendas
   *@param {string} tab rota que será consultada (city ou state ou general)
   *@param {string} from data
   *@param {string} to data
   */
  static async getSales(params) {
    try {
      /*let url;
      if (from && to) {
        url = `${URLS.SALES}/${tab}?from=${from}&to=${to}`
      } else {
        url = `${URLS.SALES}/${tab}`;
      }*/
      const url = `${URLS.SALES}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return {error: 'Falha ao consultar vendas.'};
    } catch (e) {
      return this._exception(e, 'Falha ao consultar vendas.');
    }
  }

  /**
   *@description Consulta os detalhes do relatório de vendas
   *@param {string} from data
   *@param {string} to data
   */
  static async getSalesDetails(params) {
    try {
      const url = `${URLS.SALES_DETAILS}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return {error: 'Falha ao consultar vendas.'};
    } catch (e) {
      return this._exception(e, 'Falha ao consultar vendas.');
    }
  }

  static async cancelOrder(orderUuid) {

    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/cancel`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao cancelar pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao cancelar venda.');
    }
  }

  static async returnedOrder(orderUuid) {

    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/returned`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao adicionar status de devolvido ao pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao adicionar status de devolvido ao pedido.');
    }

  }

  static async lossOrder(orderUuid) {

    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/extravio`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao adicionar status de extraviado ao pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao adicionar status de extraviado ao pedido.');
    }

  }

  static async confirmPayment(orderUuid) {
    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/paid`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao adicionar status ao pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao adicionar status ao pedido.');
    }

  }

  static async chargebackOrder(orderUuid) {

    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/chargeback`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao adicionar status de chargeback ao pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao adicionar status de chargeback ao pedido.');
    }

  }

  static async observationOrder(orderUuid, data) {

    try {
      const url = `${URLS.ORDERMERCHANTS}/${orderUuid}/observation`
      const response = await BaseAPI.put(url, data)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao adicionar observação ao pedido.'}

    } catch (e) {
      return this._exception(e, 'Falha ao adicionar observação ao pedido.');
    }

  }

  static async sendEmailAbandonedCart(cart_id, cupom) {

    try {
      const url = `${URLS.ORDERS}/abandoned/email/${cart_id}${cupom}`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao enviar e-mail.'}

    } catch (e) {
      return this._exception(e, 'Falha ao enviar e-mail.');
    }
  }

  static async sendWhatsappAbandonedCart(cart_id, cupom) {

    try {
      const url = `${URLS.ORDERS}/abandoned/whatsapp/${cart_id}${cupom}`
      const response = await BaseAPI.post(url)
      if (response.status === 200) return response.data;
      return {error: 'Falha ao enviar mensagem via Whatsapp.'}

    } catch (e) {
      return this._exception(e, 'Falha ao enviar mensagem via Whatsapp.');
    }

  }

  /**
   * Busca Logs por idOrder
   */
  static async getLogByOrderUuid(idOrder, page) {
    try {
      const url = `${URLS.ORDERS}/customers/orders/${idOrder}/logs?page=${page}`;
      const response = await BaseAPI.get(url);

      if (response.status === 200 || response.status === 204) return response.data;
      return {error: 'Falha ao buscar logs do pedido'};
    } catch (e) {
      console.log(e.message);
      return {error: 'Falha ao buscar logs do pedido'};
    }

  }

}

export default OrderAPI;
