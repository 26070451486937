import axios from 'axios';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import { decodeJWT } from '~/helpers/utils/Functions';
import NotificationStore from '~/stores/NotificationStore';

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
class BaseAPI {
  /** Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /** Métodos post */
  static async post(endpoint, data = {}, config = {}) {
    return await api.post(endpoint, data, config);
  }

  /** Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () =>
  process.env.REACT_APP_API_URL || 'https://sportbay-api.stoomlab.com.br';
//export const baseURL = () => 'http://localhost:8080';

// Cria uma instancia de api
const api = axios.create({ baseURL: baseURL(), withCredentials: true });
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // if (error.response) {
    //   let message = 'Erro inesperado';

    //   const isInternalError = error.response.status === 500;

    //   if (error?.response?.data?.message && !isInternalError) {
    //     message = error?.response?.data?.message;
    //   }

    //   const notifier = new NotificationStore();

    //   notifier.notifyError(message);
    // }
    return Promise.reject(error);
  }
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;
    if (token) {
      const currentToken = response.headers.authorization.split(' ')[1];
      const decode = decodeJWT(currentToken);
      StorageUtil.setItem(KEYS.AUTH_KEY, decode.Authorities);
      StorageUtil.setItem(KEYS.TOKEN_KEY, currentToken);
    }
    return response;
  },
  (error) => {
    // if (error.response) {
    //   let message = 'Erro inesperado';

    //   const isInternalError = error.response.status === 500;

    //   if (error?.response?.data?.message && !isInternalError) {
    //     message = error?.response?.data?.message;
    //   }

    //   const notifier = new NotificationStore();

    //   notifier.notifyError(message);
    // }
    return Promise.reject(error);
  }
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const MANAGER = '/manager';
export const URLS = {
  MANAGER,
  LOGIN: '/login',
  FILES: '/files',
  ORDERS: `${MANAGER}/orders`,
  SEARCHORDERS: `${MANAGER}/orders/search`,
  SEARCHORDERSMERCHANTS: `${MANAGER}/ordersmerchants/search`,
  CUSTOMERS: `${MANAGER}/customers`,
  MERCHANT: `${MANAGER}/merchants`,
  SUPPLIER: `${MANAGER}/suppliers`,
  AUTOMAKER: `${MANAGER}/automakers`,
  SHIPPING: `${MANAGER}/shippingcompany`,
  APPLICATION: `${MANAGER}/applications`,
  PRODUCTS: `${MANAGER}/products`,
  CATEGORIES: `${MANAGER}/categories`,
  CATEGORY: `${MANAGER}/category`,
  ACCOUNTSTYPE: `${MANAGER}/accounts/type`,
  ACCOUNTS: `${MANAGER}/accounts`,
  PROPERTY: `${MANAGER}/properties`,
  CLASSES: `${MANAGER}/properties/classes`,
  PRE_ORDERS: `${MANAGER}/preorders`,
  COMPARATIVE: `${MANAGER}/feature`,
  INSTITUTIONAL: `${MANAGER}/institutional`,
  KIT: `${MANAGER}/products/kit`,
  FEATURE: `${MANAGER}/feature`,
  BANNER: `${MANAGER}/banners`,
  COUPONS: `${MANAGER}/promotion`,
  COUPONS_SEARCH: `${MANAGER}/promotion/search`,
  SPORTBAY_PROMOTION: `${MANAGER}/sportbayPromotion`,
  ZIPCODERANGE: `${MANAGER}/zipcoderange`,
  HOME: `${MANAGER}/homes`,
  BRANDS: `${MANAGER}/brands`,
  NEWS_LETTER: `${MANAGER}/newsletters`,
  SALES: `${MANAGER}/reports/sales-general`,
  SALES_DETAILS: `${MANAGER}/reports/sales-details`,
  AUCTIONS: `${MANAGER}/auctions`,
  ORDERMERCHANTS : `${MANAGER}/ordersmerchants`
};

export default BaseAPI;
